<template>
  <div>
    <page-header title="Formulário de inscrição manual"></page-header>
    <card-loading-transparent v-if="loading"></card-loading-transparent>
    <show-form v-if="!loading" :course="course" is_manual_enrollment></show-form>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import Api from '@/api/index'
import validations from '@/mixins/validations';

  export default {
    name:'ManualEnrollment',
    mixins: [
      validations
    ],
    components: {
      ShowForm: () => import('../forms/show/ShowForm.vue'),
    },
    data() {
      return {
        course: {},
        valid: false,
        loading: false,
      }
    },
    methods: {
      getCourse(){
        this.loading = true
        Api.Course.show(this.$route.params.course_id).then(r=>r.data).then(d=>{
          this.course = {...d, kind: 'manual'}
          setTimeout(() => {
            this.loading = false
          }, 1200);
        })
      },
    },
    mounted () {
      this.getCourse()
    },
  }
</script>

<style lang="scss" scoped>

</style>